import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`404: Ooops! We couldn't find that.`}</h1>
    <p>{`You perhaps followed a link with a typo or the resource you're trying to view no longer exists.`}</p>
    <p>{`Check out `}<a parentName="p" {...{
        "href": "/cco"
      }}>{`our CCO`}</a>{`, browse the website or read our `}<a parentName="p" {...{
        "href": "/Array_Whitepaper.pdf"
      }}>{`Whitepaper`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      