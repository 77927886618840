import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Team`}</h1>
    <p>{`This diverse group of Ethereans came together to build `}<strong parentName="p">{`[array.finance]`}</strong>{`, a drastically better DeFi user experience.`}</p>
    <p>{`Normal people won't spend 36 hours per day tinkering with their portfolio, checking in on farms, rebalancing their positions, and reallocating assets to maximize profits and mitigate catastrophes, but array.finance will.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      